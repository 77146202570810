<template>
  <PanelSection title="Información de pedido" :hide-back-button="false">
    <div class="stickers-orders-form">
      <v-container>
        <v-row>
          <v-col cols="8">
            <!-- Info pedido -->
            <CustomCard>
              <h2 class="text-h6">Nº de pedido: {{ formFields.order }}</h2>
              <v-row>
                <!-- Establecimiento -->
                <v-col cols="6">
                  <h3 class="text-subtitle-1 text-decoration-underline mb-2">
                    Datos del establecimiento
                  </h3>
                  <dl v-if="place">
                    <dt class="caption">Nombre</dt>
                    <dd>{{ place.name || '-' }}</dd>
                    <dt class="caption">Email</dt>
                    <dd>{{ place.email || '-' }}</dd>
                    <dt class="caption">Teléfonos</dt>
                    <dd>
                      {{ place.phone1 || '-' }}
                      {{ place.phone2 ? `/ ${place.phone2}` : '' }}
                    </dd>
                    <dt class="caption">Área</dt>
                    <dd>
                      {{ place.area || '-' }}
                    </dd>
                    <dt class="caption">Nº de gerencia</dt>
                    <dd>
                      {{ place.management || '-' }}
                    </dd>
                    <dt class="caption">Nombre de gerencia</dt>
                    <dd>
                      {{ place.managementName || '-' }}
                    </dd>
                    <dt class="caption">Promotor</dt>
                    <dd>
                      {{ place.promoter || '-' }}
                    </dd>
                    <dt class="caption">Ruta</dt>
                    <dd>
                      {{ place.route || '-' }}
                    </dd>
                  </dl>
                </v-col>
                <!-- Dirección -->
                <v-col cols="6">
                  <h3 class="text-subtitle-1 text-decoration-underline mb-2">
                    Dirección de envío
                  </h3>
                  <dl>
                    <!-- CompanyActivactions address -->
                    <template v-if="companyActivationsAddress">
                      <dt class="caption">Dirección</dt>
                      <dd>{{ companyActivationsAddress.address }}</dd>
                      <dt class="caption">C/P</dt>
                      <dd>{{ companyActivationsAddress.postalCode }}</dd>
                    </template>
                    <!--  place address -->
                    <template v-else-if="place && place.place">
                      <dt class="caption">Provincia / Estado</dt>
                      <dd>{{ place.place.administrative_area_level_1 }}</dd>
                      <dt class="caption">País</dt>
                      <dd>{{ place.place.country }}</dd>
                      <dt class="caption">Dirección</dt>
                      <dd>
                        {{ place.place.route }} {{ place.place.postal_code }}
                      </dd>
                      <template v-if="place.extraAddress">
                        <dt class="caption">Dirección extra</dt>
                        <dd>{{ place.extraAddress }}</dd>
                      </template>
                    </template>
                  </dl>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <h3 class="text-subtitle-1 text-decoration-underline mb-2">
                    Imágenes de pegatinas
                  </h3>
                  <div v-if="place" class="d-flex">
                    <a :href="place.stickers['sticker30']" target="_blank">
                      <v-img
                        max-height="150"
                        max-width="150"
                        :src="place.stickers['sticker30']"
                      ></v-img>
                    </a>
                    <a :href="place.qrCode" target="_blank">
                      <v-img
                        max-height="150"
                        max-width="150"
                        :src="place.qrCode"
                      ></v-img>
                    </a>
                  </div>
                </v-col>
                <v-col cols="6">
                  <h3 class="text-subtitle-1 text-decoration-underline mb-2">
                    Número de pegatinas
                  </h3>
                  <dl v-if="formFields.stickers">
                    <dt class="caption">Pequeñas - 12cm</dt>
                    <dd>{{ formFields.stickers.sticker12 }}</dd>
                    <dt class="caption">Grandes - 30cm</dt>
                    <dd>{{ formFields.stickers.sticker30 }}</dd>
                  </dl>
                </v-col>
              </v-row>
            </CustomCard>
          </v-col>
          <v-col cols="4">
            <!-- Edición pedido -->
            <CustomCard>
              <v-form novalidate @submit.prevent="onSubmit">
                <!-- Status -->
                <v-select
                  v-model="$v.formFields.status.$model"
                  :items="getStatusByRole"
                  item-value="id"
                  item-text="label"
                  label="Estado pedido"
                  :error-messages="checkFieldErrors('status')"
                  @input="touchField('status')"
                  @blur="touchField('status')"
                />
                <!-- Delivery -->
                <v-radio-group
                  v-if="showDeliveryField"
                  v-model="$v.formFields.deliveryType.$model"
                >
                  <v-radio
                    v-for="type in deliveryTypeOptions"
                    :key="type.id"
                    :label="type.label"
                    :value="type.id"
                  ></v-radio>
                </v-radio-group>
                <!-- Action buttons -->
                <v-card-actions>
                  <FormButtons
                    accept-button-text="GUARDAR"
                    :accept-button-loading="formProcessing"
                    @onClickCancelButton="handleClickCancelButton"
                  />
                </v-card-actions>
              </v-form>
            </CustomCard>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Constants
import { ROLES, STICKERS_STATUS } from '@/constants'
// Components
import CustomCard from '@/components/ui/CustomCard'
import FormButtons from '@/components/ui/FormButtons'
import PanelSection from '@/components/ui/PanelSection'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import formMixin from '@/mixins/formMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import {
  getStickersOrdersById,
  updateStickersOrders
} from '@/services/stickersOrders'
import { getPlaceById } from '@/services/place'
import { getEveryPanelCompanyActivations } from '@/services/panelCompanyActivations'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'StickersOrderForm',
  components: { CustomCard, FormButtons, PanelSection },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Data item
      id: this.$route.params.id || null,
      place: null,
      companyActivationsAddress: null,
      deliveryTypeOptions: [
        {
          id: 'send',
          label: 'Enviar a una dirección'
        },
        {
          id: 'collected',
          label: 'Recoger en tienda'
        }
      ],
      // Form
      formFields: {
        status: null,
        deliveryType: 0
      },
      // Validations
      formFieldsValidations: {
        status: {
          required: 'Campo obligatorio'
        },
        deliveryType: {
          required: 'Campo obligatorio'
        }
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * Must hide the "delivery" field (form)
     *
     * @return {boolean}
     */
    showDeliveryField() {
      return (
        this.userData.role === ROLES.admin.value ||
        this.userData.role === ROLES.commercial.value
      )
    },
    /**
     * Get the differents status app by role
     *
     * @return {array} - status array
     */
    getStatusByRole() {
      const everyStatus = Object.keys(STICKERS_STATUS)
      const roleAdmin = ROLES.admin.value
      const roleCommercial = ROLES.commercial.value
      const roleProvider = ROLES.provider.value
      const filters = {
        [roleAdmin]: everyStatus,
        [roleCommercial]: everyStatus.filter(state => {
          return (
            [
              STICKERS_STATUS.accepted.value,
              STICKERS_STATUS.pending.value,
              STICKERS_STATUS.cancelled.value
            ].indexOf(state) > -1
          )
        }),
        [roleProvider]: everyStatus.filter(state => {
          return (
            [STICKERS_STATUS.print.value, STICKERS_STATUS.sent.value].indexOf(
              state
            ) > -1
          )
        })
      }

      return filters[this.userData.role].map(item => {
        return {
          id: item,
          label: STICKERS_STATUS[item].label
        }
      })
    }
  },
  async mounted() {
    await this.setData(this.id)
  },
  methods: {
    /**
     * Handle click on "Cancel" Button
     */
    handleClickCancelButton() {
      this.goBackToList()
    },
    /**
     * Go back to Sticker orders list
     */
    goBackToList() {
      this.routerPushTo({ path: '/stickers-orders' })
    },
    // Seteamos toda la información necesaria
    async setData(id) {
      // Obtenemos el detalle del pedido mediante la id
      const order = await getStickersOrdersById(id)

      // Comprobamos si se ha podido obtener el pedido
      if (order) {
        // Seteamos la orden en el estado del formulario
        this.formFields = { ...this.formFields, ...order }

        // Obtenemos detalles del pedido
        const placeId = Object.keys(order.places)[0]
        this.place = await getPlaceById(placeId)
        // Si no existen datos de dirección en el
        // establecimiento, buscamos los datos rellenados
        // por el comercial en "companyActivations"
        if (
          this.place.place === null ||
          (typeof this.place.place === 'object' &&
            Object.keys(this.place.place).length === 0)
        ) {
          const companyActivationsData = await getEveryPanelCompanyActivations({
            constraints: [['companyKeysId', '==', order.companyKeysId]],
            limit: 1
          })

          if (
            Array.isArray(companyActivationsData) &&
            companyActivationsData.length
          ) {
            // eslint-disable-next-line prefer-destructuring
            this.companyActivationsAddress = companyActivationsData[0]
          }
        }
      } else {
        this.goBackToList()
      }
    },

    // Modificamos el pedido en DBs
    async afterSubmit() {
      try {
        // Seteamos los nuevos valores del pedido
        await updateStickersOrders({
          id: this.formFields.id,
          status: this.formFields.status,
          deliveryType: this.formFields.deliveryType
        })
        // Indicamos que todo ha funcionado correctamente
        this.modifyAppAlert({
          text: 'Los cambios se guardaron correctamente',
          visible: true
        })
        // Devolvemos al listado
        this.goBackToList()
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      status: {
        required
      },
      deliveryType: {
        required
      }
    }
  }
}
</script>
