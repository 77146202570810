// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class StickersOrders extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    companies: {}, // Relation object (keys)
    places: {}, // Relation object (keys)
    companyName: null,
    order: 0,
    stickers: {},
    status: null, // pending cancelled accepted print sent,
    deliveryType: 'send' // send or collected
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.panel}stickersOrders`)
  }

  // Here you can extend Company with custom methods
}
