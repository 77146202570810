import StickersOrders from '@/firebase/models/stickersOrders'

const stickersOrders = new StickersOrders()

/**
 * Get stickersOrders by UID
 */
export async function getStickersOrdersById(id) {
  const returnData = await stickersOrders.read(id)
  return returnData
}

/**
 * Get all stickersOrders
 *
 * @param {object} pagination - pagination options
 */
export async function getAllStickersOrders(pagination) {
  const returnData = await stickersOrders.readAll(pagination)
  return returnData
}

/**
 * Update stickersOrders in database
 */
export async function updateStickersOrders(data) {
  const returnData = await stickersOrders.update(data)
  return returnData
}

/**
 * Create new sticker
 */
export async function createStickerOrder(data, id = null) {
  const order = await stickersOrders.create(data, id)
  return order
}

/**
 * Return orders by PLACE_ID
 */
export async function getAllStickersOrdersByPlaceId(placeId) {
  if (!placeId) return []

  const orders = await stickersOrders.readAll([
    [`places.${placeId}`, '==', true]
  ])

  return orders.sort((a, b) => b.order - a.order)
}

/**
 * Update order sticker
 */
export async function updateStickersOrder(data) {
  const company = await stickersOrders.update(data)
  return company
}

/**
 * Remove order sticker
 */
export async function deleteStickersOrder(id) {
  await stickersOrders.delete(id)
}
